import { Component } from '@angular/core';

@Component({
  selector: 'app-cms',
  template: '<router-outlet></router-outlet>',
  styles: []
})
export class CmsComponent {

}
