import { Component, OnInit } from '@angular/core';
import { PmsService } from 'app/shared/services/pms/pms.service';

@Component({
  selector: 'app-pms',
  template: '<router-outlet></router-outlet>',
  styles: []
})

export class PmsComponent implements OnInit {

  constructor(private api: PmsService) { }

  ngOnInit(): void {
    this.getVendorsList();
  }

  getVendorsList() {
    this.api.getVendors().subscribe(
      (result) => {
        this.api.updateVendors(result);
      })
  }

}
