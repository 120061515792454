import { Routes } from '@angular/router';
import { AdminLayoutComponent } from './shared/components/layouts/admin-layout/admin-layout.component';
import { AuthLayoutComponent } from './shared/components/layouts/auth-layout/auth-layout.component';
import { AuthGuard } from './shared/guards/auth.guard';
import { PmsComponent } from './sections/pms/pms.component';
import { CmsComponent } from './sections/cms/cms.component';
import { SalesComponent } from './sections/sales/sales.component';
import { AdministrationComponent } from './sections/administration/administration.component';
import { LandingpagesComponent } from './sections/landingpages/landingpages.component';

export const rootRouterConfig: Routes = [
  {
    path: '',
    redirectTo: 'cms/home',
    pathMatch: 'full'
  },
  {
    path: '',
    component: AuthLayoutComponent,
    children: [
      {
        path: 'sessions',
        loadChildren: () => import('./views/sessions/sessions.module').then(m => m.SessionsModule),
        data: { title: 'Session' }
      }
    ]
  },
  {
    path: '',
    component: AdminLayoutComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: 'home',
        component: PmsComponent,
        loadChildren: () => import('./sections/home/home.module').then(m => m.HomeModule),
        data: { title: 'Home', breadcrumb: 'Home' }
      },
      {
        path: 'pms',
        component: PmsComponent,
        loadChildren: () => import('./sections/pms/pms.module').then(m => m.PMSModule),
        data: { title: 'PMS', breadcrumb: 'PMS' }
      },
      {
        path: 'cms',
        component: CmsComponent,
        loadChildren: () => import('./sections/cms/cms.module').then(m => m.CmsModule),
        data: { title: 'CMS', breadcrumb: 'CMS' }
      },
      {
        path: 'sales',
        component: SalesComponent,
        loadChildren: () => import('./sections/sales/sales.module').then(m => m.SalesModule),
        data: { title: 'Sales', breadcrumb: 'Sales' }
      },
      {
        path: 'administration',
        component: AdministrationComponent,
        loadChildren: () => import('./sections/administration/administration.module').then(m => m.AdministrationModule),
        data: { title: 'Administration', breadcrumb: 'Administration' }
      },
      {
        path: 'landing-pages',
        component: LandingpagesComponent,
        loadChildren: () => import('./sections/landingpages/landingpages.module').then(m => m.LandingpagesModule),
        data: { title: 'Landingpages', breadcrumb: 'Landingpages' }
      },
      {
        path: 'general',
        loadChildren: () => import('./sections/general/general.module').then(m => m.GeneralModule),
        data: { title: 'General', breadcrumb: 'General' }
      },
      {
        path: 'others',
        loadChildren: () => import('./views/others/others.module').then(m => m.OthersModule),
        data: { title: 'Others', breadcrumb: 'OTHERS' }
      },
      {
        path: 'search',
        loadChildren: () => import('./views/search-view/search-view.module').then(m => m.SearchViewModule)
      }
    ]
  },
  {
    path: '**',
    redirectTo: 'sessions/404'
  }
];

