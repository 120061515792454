import { Injectable } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpErrorResponse
} from "@angular/common/http";
import { Observable, catchError, throwError } from "rxjs";
import { JwtAuthService } from "../services/auth/jwt-auth.service";
import { ToastrService } from "ngx-toastr";

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

  constructor(private jwtAuth: JwtAuthService, private toastr: ToastrService) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    var token = this.jwtAuth.token || this.jwtAuth.getJwtToken();

    var changedReq;

    if (token) {

      changedReq = req.clone({
        setHeaders: {
          Authorization: `Bearer ${token}`
        },
      });

    } else {

      changedReq = req;

    }
    return next.handle(changedReq).pipe(
      catchError((error: any) => {
        if (error instanceof HttpErrorResponse && error.status === 401) {
          // Handle 401 Unauthorized error here
          this.jwtAuth.signout();
        }
        else {
          console.log(error?.error)
          // this.toastr.error(error?.error ? this.displayErrorMessages(error?.error) : error?.message ?? "Something went wrong.", "Try Again!")
          this.toastr.error(error?.error?.message || error?.message || "Something went wrong.");
        }
        return throwError(error);
      })
    );
  }

  displayErrorMessages(error): string[] {
    const errorMessages: string[] = [];

    Object.keys(error).forEach(key => {
      const errorMessagesForKey = error[key].map(message => `${message}`);
      errorMessages.push(...errorMessagesForKey);
    });

    return errorMessages;
  }
}
