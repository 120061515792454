import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { NavigationService } from "../../../shared/services/navigation.service";
import { Subscription } from 'rxjs';
import { ThemeService } from '../../../shared/services/theme.service';
import { TranslateService } from '@ngx-translate/core';
import { LayoutService } from '../../services/layout.service';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';
import { NavigationEnd, Router } from '@angular/router';

@Component({
  selector: 'app-header-top',
  templateUrl: './header-top.component.html'
})
export class HeaderTopComponent implements OnInit, OnDestroy {
  layoutConf: any;
  menuItems: any;
  menuItemSub: Subscription;
  permissionCheck = false;
  egretThemes: any[] = [];
  public availableLangs = [{
    name: 'EN',
    code: 'en',
    flag: 'us'
  }, {
    name: 'ES',
    code: 'es',
    flag: 'es'
  }];
  currentLang = this.availableLangs[0];

  @Input() notificPanel;
  constructor(
    private layout: LayoutService,
    private navService: NavigationService,
    public themeService: ThemeService,
    public translate: TranslateService,
    public jwtAuth: JwtAuthService,
    private router: Router
  ) {

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        if (['cms', 'sales', 'administration', 'landing-pages','home'].includes(event.url.split('/')[1])) {
          this.layout.publishLayoutChange({ showSideNav: true });
        } else if (event.url.split('/')[1] == 'pms' && this.router.url.split('/')[2] != 'products') {
          this.layout.publishLayoutChange({ showSideNav: true });
        } else {
          this.layout.publishLayoutChange({ showSideNav: false });
        }
      }
    });
  }

  ngOnInit() {

    if (['cms', 'sales', 'administration', 'landing-pages','home'].includes(this.router.url.split('/')[1])) {
      this.layout.publishLayoutChange({ showSideNav: true });
    } else if (this.router.url.split('/')[1] == 'pms' && this.router.url.split('/')[2] != 'products') {
      this.layout.publishLayoutChange({ showSideNav: true });
    } else {
      this.layout.publishLayoutChange({ showSideNav: false });
    }

    this.layoutConf = this.layout.layoutConf;
    this.egretThemes = this.themeService.egretThemes;
    this.translate.use(this.currentLang.code);
    this.menuItemSub = this.navService.menuItems$
      .subscribe(res => {
        res = res.filter(item => item.type !== 'icon' && item.type !== 'separator');
        let limit = 10
        let mainItems: any[] = res.slice(0, limit)
        if (res.length <= limit) {
          return this.menuItems = mainItems
        }
        let subItems: any[] = res.slice(limit, res.length - 1)
        mainItems.push({
          name: 'More',
          type: 'dropDown',
          tooltip: 'More',
          icon: 'more_horiz',
          sub: subItems
        })
        this.menuItems = mainItems
      })

    this.getPermissions();
  }
  ngOnDestroy() {
    this.menuItemSub.unsubscribe()
  }
  setLang(lng) {
    this.currentLang = lng;
    this.translate.use(lng.code);
  }
  changeTheme(theme) {
    this.layout.publishLayoutChange({ matTheme: theme.name })
  }
  toggleNotific() {
    this.notificPanel.toggle();
  }
  toggleSidenav() {
    if (this.layoutConf.sidebarStyle === 'closed') {
      return this.layout.publishLayoutChange({
        sidebarStyle: 'full'
      })
    }
    this.layout.publishLayoutChange({
      sidebarStyle: 'closed'
    })
  }

  getPermissions() {
    this.jwtAuth.getPermissions().subscribe(
      result => {
        this.jwtAuth.updatePermimissions({
          permissions: result?.data ?? [],
          is_super_admin: result?.is_super_admin,
          user_type: result?.user_type
        });
        if (result?.user_type == 'supplier') this.menuItems = this.menuItems.filter(ae => ["PMS", "Sales"].includes(ae.name));
        this.permissionCheck = true;
      }
    )
  }

}
