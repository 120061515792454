import { Component, Input, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import { PmsService } from 'app/shared/services/pms/pms.service';
import { JwtAuthService } from 'app/shared/services/auth/jwt-auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.template.html'
})
export class SidenavComponent {
  @Input('items') public menuItems: any[] = [];
  @Input('hasIconMenu') public hasIconTypeMenuItem: boolean;
  @Input('iconMenuTitle') public iconTypeMenuTitle: string;
  @ViewChild('sidenav') sidenav: ElementRef;
  private dataSubscription: Subscription;
  productId;
  current_route;
  userType: string;

  constructor(private api: PmsService, private router: Router, private jwt: JwtAuthService) {
    this.router.events.subscribe(() => {
      this.current_route = this.router.url.split('/')[1];
    });
  }

  ngOnInit() {
    this.current_route = this.router.url.split('/')[1];
    this.dataSubscription = this.api.productId$.subscribe((data) => {
      this.productId = data;
    });
    this.dataSubscription = this.api.productId$.subscribe((data) => {
      this.productId = data;
    });
    this.dataSubscription = this.jwt.permissions$.subscribe((data) => {
      this.userType = data.user_type;
    });
  }
  ngAfterViewInit() {
    setTimeout(() => {
      const links = this.sidenav.nativeElement.querySelectorAll('li[appdropdownlink]');
      [...links].forEach(link => {
        if (link.querySelector('a.open')) {
          link.classList.add('open');
        }
      })
    })
  }

  ngOnDestroy() {
    this.dataSubscription.unsubscribe();
  }

}