import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

interface IMenuItem {
  type: 'link' | 'dropDown' | 'icon' | 'separator' | 'extLink';
  name?: string; // Used as display text for item and title for separator type
  state?: string; // Router state
  product?: boolean; // Product state
  icon?: string; // Material icon name
  svgIcon?: string; // UI Lib icon name
  tooltip?: string; // Tooltip text
  url_slug?: string; // Tooltip text
  disabled?: boolean; // If true, item will not be appeared in sidenav.
  sub?: IChildItem[]; // Dropdown items
  side_nav?: IMenuItem[]; // Dropdown items
  badges?: IBadge[];
}
interface IChildItem {
  type?: string;
  name: string; // Display text
  state?: string; // Router state
  product?: boolean; // Product state
  icon?: string;  // Material icon name
  svgIcon?: string; // UI Lib icon name
  sub?: IChildItem[];
}

interface IBadge {
  color: string; // primary/accent/warn/hex color codes(#fff000)
  value: string; // Display text
}

@Injectable()
export class NavigationService {

  plainMenu: IMenuItem[] = [
    {
      name: 'Home',
      type: 'link',
      tooltip: 'Home',
      icon: 'home',
      state: 'home',
      url_slug: 'home',
      sub: [
        { name: 'Dashboard', state: 'home/dashboard' },
      ],
      side_nav: [
        {
          name: 'Dashboard',
          type: 'link',
          tooltip: 'dashboard',
          state: 'home/dashboard',
          icon: 'dashboard',
        },
        {
          name: 'White Label',
          type: 'link',
          tooltip: 'whitelabel',
          state: 'home/whitelabels',
          icon: 'dashboard',
        },
        {
          name: 'Out API',
          type: 'link',
          tooltip: 'outapi',
          state: 'home/outapi',
          icon: 'dashboard',
        },
        // {
        //   name: 'Travel Agent',
        //   type: 'link',
        //   tooltip: 'travelagent',
        //   state: 'home/travelagents',
        //   icon: 'dashboard',
        // },
      ]
    },
    {
      name: 'CMS',
      type: 'link',
      state: 'cms',
      tooltip: 'Content Management',
      icon: 'desktop_mac',
      url_slug: 'cms',
      side_nav: [{
        name: 'Home',
        type: 'link',
        tooltip: 'Content Management',
        state: 'cms/home',
        icon: 'home',
      },
      {
        name: 'Contact Us',
        type: 'link',
        tooltip: 'Content Management',
        state: 'cms/contact',
        icon: 'perm_phone_msg',
      },
      {
        name: 'About Us',
        type: 'link',
        tooltip: 'Content Management',
        state: 'cms/about',
        icon: 'info',
      },
      {
        name: 'Testimonials',
        type: 'link',
        tooltip: 'Testimonials',
        state: 'cms/testimonials',
        icon: 'chat',
      },
      {
        name: 'News',
        type: 'link',
        tooltip: 'News & Blogs',
        state: 'cms/news',
        icon: 'description',
      },
      {
        name: 'Blogs',
        type: 'link',
        tooltip: 'Blogs',
        state: 'cms/blogs',
        icon: 'chrome_reader_mode',
      },
      {
        name: 'Countries',
        type: 'link',
        tooltip: 'Countries',
        state: 'cms/countries',
        icon: 'public',
      },
      {
        name: 'Currencies',
        type: 'link',
        tooltip: 'Currencies',
        state: 'cms/currencies',
        icon: 'monetization_on',
      },
      {
        name: 'Destinations',
        type: 'link',
        tooltip: 'Destinations',
        state: 'cms/destinations',
        icon: 'directions_transit',
      },
      {
        name: 'Attributes',
        type: 'link',
        tooltip: 'Attributes',
        state: 'cms/attributes',
        icon: 'description',
      },
      {
        name: 'Categories',
        type: 'link',
        tooltip: 'Categories',
        state: 'cms/categories',
        icon: 'border_all',
      },

      ]
    },
    {
      name: 'PMS',
      type: 'link',
      tooltip: 'Product Management',
      icon: 'shopping_cart',
      state: 'pms',
      url_slug: 'pms',
      sub: [
        { name: 'Products', state: 'pms/products' },
      ],
      side_nav: [{
        name: 'Website',
        type: 'link',
        tooltip: 'Product Management',
        state: 'pms/add-product',
        product: true,
        icon: 'web',
      },
      {
        name: 'Tour Details',
        type: 'link',
        tooltip: 'Product Management',
        state: 'pms/tour-details',
        product: true,
        icon: 'directions_car',
      },
      {
        name: 'Tickets & Pricing',
        type: 'link',
        tooltip: 'Product Management',
        state: 'pms/pricing',
        product: true,
        icon: 'attach_money',
      },
      {
        name: 'Categories',
        type: 'link',
        tooltip: 'Product Management',
        state: 'pms/categories',
        product: true,
        icon: 'apps',
      },
      {
        name: 'Up-Sell',
        type: 'link',
        tooltip: 'Up-Sell',
        state: 'pms/upsell-product',
        product: true,
        icon: 'trending_up',
      },
      {
        name: 'Cross-Sell',
        type: 'link',
        tooltip: 'Cross-Sell',
        state: 'pms/crosssell-product',
        product: true,
        icon: 'trending_down',
      },
      {
        name: 'Reviews & Ratings',
        type: 'link',
        tooltip: 'Reviews & Ratings',
        state: 'pms/reviews-ratings',
        product: true,
        icon: 'chat_bubble_outline',
      }]
    },
    {
      name: 'Sales',
      type: 'link',
      state: 'sales',
      tooltip: 'Sales Management',
      icon: 'wallet',
      url_slug: 'sales',
      side_nav: [{
        name: 'Bookings',
        type: 'link',
        tooltip: 'Sales Management',
        state: 'sales/bookings',
        icon: 'view_list',
      },
      {
        name: 'Booking Enquiries',
        type: 'link',
        tooltip: 'Group Bookings',
        state: 'sales/group-bookings',
        icon: 'view_module',
      },
      {
        name: 'Transfers Bookings',
        type: 'link',
        tooltip: 'Transfers Bookings',
        state: 'sales/transfers-bookings',
        icon: 'directions_car',
      },
      {
        name: 'Customers',
        type: 'link',
        tooltip: 'Customers',
        state: 'sales/customers',
        icon: 'group',
      },
      {
        name: 'Customer Groups',
        type: 'link',
        tooltip: 'Customer Groups',
        state: 'sales/customer-groups',
        icon: 'group_add',
      },
      {
        name: 'Club Pass',
        type: 'link',
        tooltip: 'Club Pass',
        state: 'sales/club-pass',
        icon: 'confirmation_number',
      },
      {
        name: 'Coupons',
        type: 'link',
        tooltip: 'Coupons',
        state: 'sales/coupons',
        icon: 'local_offer',
      },
      {
        name: 'Attraction Pass',
        type: 'link',
        tooltip: 'Attraction Pass',
        state: 'sales/attraction',
        icon: 'picture_in_picture',
      },
      {
        name: 'Affiliates',
        type: 'link',
        tooltip: 'Affiliates',
        state: 'sales/affiliates',
        icon: 'person_pin',
      },
      {
        name: 'Contact Leads',
        type: 'link',
        tooltip: 'Contact Leads',
        state: 'sales/contact-leads',
        icon: 'contact_phone',
      },
      {
        name: 'Voucher Portal',
        type: 'link',
        tooltip: 'Voucher Portal',
        state: 'sales/voucher-portal',
        icon: 'confirmation_number',
      }]
    },
    {
      name: 'Marketing',
      type: 'link',
      tooltip: 'Marketing',
      icon: 'library_books',
      state: 'landing-pages',
      url_slug: 'landing-pages',
      sub: [
        { name: 'DPR-Landingpage', state: 'landing-pages/dpr' },
      ],
      side_nav: [
        {
          name: 'Landingpage',
          type: 'link',
          tooltip: 'DPR-Landingpage',
          state: 'landing-pages/dpr',
          icon: 'pages',
        },

      ]
    },
    {
      name: 'Administration',
      type: 'link',
      state: 'administration',
      tooltip: 'Administration',
      icon: 'vpn_key',
      url_slug: 'administration',
      side_nav: [{
        name: 'Merchants',
        type: 'link',
        tooltip: 'Merchants',
        state: 'administration/merchants',
        icon: 'group_add',
      },
      {
        name: 'Integrations',
        type: 'link',
        tooltip: 'Integrations',
        state: 'administration/integrations',
        icon: 'layers',
      },
      {
        name: 'White Label',
        type: 'link',
        tooltip: 'White Label',
        state: 'administration/white-label',
        icon: 'layers',
      },
      {
        name: 'Staffs',
        type: 'link',
        tooltip: 'Staffs',
        state: 'administration/staffs',
        icon: 'group',
      },
      {
        name: 'Vendors',
        type: 'link',
        tooltip: 'Vendors',
        state: 'administration/vendors',
        icon: 'picture_in_picture',
      },
      {
        name: 'Notifications',
        type: 'link',
        tooltip: 'Notifications',
        state: 'administration/notification-templates',
        icon: 'notifications',
      },
      {
        name: 'Travel Agents',
        type: 'link',
        tooltip: 'Travel Agents',
        state: 'administration/travel-agents',
        icon: 'group',
      },
      {
        name: 'Suppliers',
        type: 'link',
        tooltip: 'TSuppliers',
        state: 'administration/suppliers',
        icon: 'local_shipping',
      },
      {
        name: 'Reviews & Ratings',
        type: 'link',
        tooltip: 'Reviews & Ratings',
        state: 'administration/reviews-ratings',
        icon: 'chat_bubble_outline',
      }]
    },


  ];

  // Icon menu TITLE at the very top of navigation.
  // This title will appear if any icon type item is present in menu.
  iconTypeMenuTitle = 'Frequently Accessed';
  // sets iconMenu as default;
  menuItems = new BehaviorSubject<IMenuItem[]>(this.plainMenu);
  // navigation component has subscribed to this Observable
  menuItems$ = this.menuItems.asObservable();
  constructor() { }

  // Customizer component uses this method to change menu.
  // You can remove this method and customizer component.
  // Or you can customize this method to supply different menu for
  // different user type.
  publishNavigationChange(menuType: string) {
    // switch (menuType) {
    //   case 'separator-menu':
    //     this.menuItems.next(this.separatorMenu);
    //     break;
    //   case 'icon-menu':
    //     this.menuItems.next(this.iconMenu);
    //     break;
    //   default:
    //     this.menuItems.next(this.plainMenu);
    // }
    this.menuItems.next(this.plainMenu);
  }
}
