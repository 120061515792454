<div class="text-center">
    <h6 class="m-0 !p-4" *ngIf="!!title" style="white-space: pre-wrap; line-height: 1.4;">{{ title }}</h6>
    <div mat-dialog-content>
        <!-- <mat-spinner [style.margin]="'auto'"></mat-spinner> -->
        <div class='cssload-loader'>
            <div class='cssload-inner cssload-one'></div>
            <div class='cssload-inner cssload-two'></div>
            <div class='cssload-inner cssload-three'></div>
        </div>
    </div>
</div>