import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class GeneralService {

  constructor(private http: HttpClient) { }

  private gallerySubject = new BehaviorSubject<any>(null);
  gallery$ = this.gallerySubject.asObservable();

  private countrySubject = new BehaviorSubject<any>(null);
  country$ = this.countrySubject.asObservable();

  private unitTypeSubject = new BehaviorSubject<any>(null);
  unitType$ = this.unitTypeSubject.asObservable();

  updateCountries(data: any) {
    this.countrySubject.next(data);
  }

  updateGallery(data: any) {
    this.gallerySubject.next(data);
  }

  updateUnitTypes(data: any) {
    this.unitTypeSubject.next(data);
  }


  getGallery(page, page_size, document_type?): Observable<any> {
    return this.http.get(`${environment.apiURL}/gallery` +
      `?page=${page}` +
      `&page_size=${page_size}` +
      `${document_type ? '&document_type=' : ''}${document_type ? document_type : ''}`);
  }

  getGalleryNoPage(): Observable<any> {
    return this.http.get(`${environment.apiURL}/gallery`);
  }

  uploadFile(data): Observable<any> {
    return this.http.post(`${environment.apiURL}/gallery`, data, {
      reportProgress: true,
      observe: 'events',
    });
  }

  updateFile(id, data): Observable<any> {
    return this.http.put(`${environment.apiURL}/gallery/${id}`, data);
  }

  getUnitType() {
    return this.http.get(`${environment.apiURL}/unit-type`);
  }

  deleteApi(id, api_url) {
    return this.http.delete(`${environment.apiURL}/${api_url}/${id}`);
  }

  getProfileDetails() {
    return this.http.get(`${environment.apiURL}/admin_profile`);
  }

  setProfileDetails(data): Observable<any> {
    return this.http.put(`${environment.apiURL}/admin_profile`, data);
  }

  getItem(key) {
    return localStorage.getItem(key) ? JSON.stringify(localStorage.getItem(key)) : null;
  }

  setItem(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  }

  getSessionItem(key) {
    return JSON.parse(sessionStorage.getItem(key) || null);
  }

  setSessionItem(key, value) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }
}
