import { Component, OnInit, OnDestroy, AfterViewInit } from "@angular/core";
import { NavigationService } from "../../../shared/services/navigation.service";
import { ThemeService } from "../../services/theme.service";
import { Subscription } from "rxjs";
import { ILayoutConf, LayoutService } from "app/shared/services/layout.service";
import { JwtAuthService } from "app/shared/services/auth/jwt-auth.service";
import { NavigationEnd, Router } from "@angular/router";

@Component({
  selector: "app-sidebar-side",
  templateUrl: "./sidebar-side.component.html"
})
export class SidebarSideComponent implements OnInit, OnDestroy, AfterViewInit {
  public menuItems: any[];
  public hasIconTypeMenuItem: boolean;
  public iconTypeMenuTitle: string;
  private menuItemsSub: Subscription;
  public layoutConf: ILayoutConf;

  constructor(
    private navService: NavigationService,
    public themeService: ThemeService,
    private layout: LayoutService,
    public jwtAuth: JwtAuthService,
    private router: Router
  ) {
    // this.router.events.subscribe((event: any) => {
    //   if (event instanceof NavigationEnd) {
    //     if (event.url.split('/')[1] == 'cms') {
    //       this.layout.publishLayoutChange({ showSideNav: true });
    //     } else {
    //       this.layout.publishLayoutChange({ showSideNav: false });
    //     }
    //   }
    // });

    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        this.menuItemsSub = this.navService.menuItems$.subscribe(menuItem => {
          let routes = menuItem.filter(ae => ae.url_slug == this.router.url.split('/')[1])
          this.menuItems = routes.length > 0 ? routes[0].side_nav : [];
        });
      }
    });

  }

  ngOnInit() {
    this.iconTypeMenuTitle = this.navService.iconTypeMenuTitle;

    this.menuItemsSub = this.navService.menuItems$.subscribe(menuItem => {
      let routes = menuItem.filter(ae => ae.url_slug == this.router.url.split('/')[1])
      this.menuItems = routes.length > 0 ? routes[0].side_nav : [];
      //Checks item list has any icon type.
      this.hasIconTypeMenuItem = !!this.menuItems.filter(
        item => item.type === "icon"
      ).length;
    });
    this.layoutConf = this.layout.layoutConf;

    // if (this.router.url.split('/')[1] == 'cms') {
    //   this.layout.publishLayoutChange({ showSideNav: true });
    // } else {
    //   this.layout.publishLayoutChange({ showSideNav: false });
    // }
  }
  ngAfterViewInit() { }
  ngOnDestroy() {
    if (this.menuItemsSub) {
      this.menuItemsSub.unsubscribe();
    }
  }
  toggleCollapse() {
    if (
      this.layoutConf.sidebarCompactToggle
    ) {
      this.layout.publishLayoutChange({
        sidebarCompactToggle: false
      });
    } else {
      this.layout.publishLayoutChange({
        // sidebarStyle: "compact",
        sidebarCompactToggle: true
      });
    }
  }
}
