import { Component } from '@angular/core';

@Component({
  selector: 'app-landingpages',
  template: '<router-outlet></router-outlet>',
  styles: []
})
export class LandingpagesComponent {

}
