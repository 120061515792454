import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, Inject } from '@angular/core';

@Component({
  selector: 'app-confirm',
  template: `<div class="p-4">
    <h1 class="text-lg pl-1 !mb-2">{{ data.title }}</h1>
    <div class="mb-3 pl-1" *ngIf="!!data.message">{{ data.message }}</div>
    <div mat-dialog-actions class="!pb-2 flex float-end mb-2">
       <button
    type="button"
    class="btn btn-danger"
    (click)="dialogRef.close(false)">Cancel</button>
    &nbsp;
    <span class="mx-2"></span>
     <button
    type="button"
    class="btn btn-success"
    (click)="dialogRef.close(true)">OK</button>
    </div>
  </div>`,
})
export class AppComfirmComponent {
  constructor(
    public dialogRef: MatDialogRef<AppComfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }
}