import { Injectable } from "@angular/core";
import { LocalStoreService } from "../local-store.service";
import { HttpClient } from "@angular/common/http";
import { Router, ActivatedRoute } from "@angular/router";
import { map, catchError, delay } from "rxjs/operators";
import { User } from "../../models/user.model";
import { of, BehaviorSubject, throwError, Observable } from "rxjs";
import { environment } from "environments/environment";

// ================= only for demo purpose ===========
const DEMO_TOKEN = "";

const DEMO_USER: User = {
  id: "5b700c45639d2c0c54b354ba",
  displayName: "Watson Joyce",
  role: "SA",
};
// ================= you will get those data from server =======

@Injectable({
  providedIn: "root",
})
export class JwtAuthService {
  token;
  isAuthenticated: Boolean;
  user: User = {};
  user$ = (new BehaviorSubject<User>(this.user));
  signingIn: Boolean;
  return: string;
  TRIPXOXO_TOKEN = "TRIPXOXO_TOKEN";
  APP_USER = "TRIPXOXO_USER";
  permissionData: any;

  constructor(
    private ls: LocalStoreService,
    private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.route.queryParams
      .subscribe(params => this.return = params['return'] || '/');

    this.permissions$.subscribe((permissionsData) => {
      this.permissionData = permissionsData;
    });
  }

  private permissionSubject = new BehaviorSubject<any>({
    permissions: [],
    is_super_admin: true,
    user_type: ''
  });
  permissions$ = this.permissionSubject.asObservable();

  updatePermimissions(data: any) {
    this.permissionSubject.next(data);
  }

  public signin(username, password) {
    this.signingIn = true;
    return this.http.post(`${environment.apiURL}/login`, { username, password })
      .pipe(
        map((res: any) => {
          this.setUserAndToken(res.access, res.user_type, !!res);
          this.signingIn = false;
          return res;
        }),
        catchError((error) => {
          return throwError(error);
        })
      );
  }

  /*
    checkTokenIsValid is called inside constructor of
    shared/components/layouts/admin-layout/admin-layout.component.ts
  */
  public checkTokenIsValid() {
    return of(DEMO_USER)
      .pipe(
        map((profile: User) => {
          this.setUserAndToken(this.getJwtToken(), profile, true);
          this.signingIn = false;
          return profile;
        }),
        catchError((error) => {
          return of(error);
        })
      );

  }

  public signout() {
    this.setUserAndToken(null, null, false);
    sessionStorage.clear();
    this.router.navigateByUrl("sessions/signin");
  }

  isLoggedIn(): Boolean {
    return !!this.getJwtToken();
  }

  getJwtToken() {
    return this.ls.getItem(this.TRIPXOXO_TOKEN);
  }

  getUser() {
    return this.ls.getItem(this.APP_USER);
  }

  setUserAndToken(token: String, user: User, isAuthenticated: Boolean) {
    this.isAuthenticated = isAuthenticated;
    this.token = token;
    this.user = user;
    this.user$.next(user);
    this.ls.setItem(this.TRIPXOXO_TOKEN, token);
    this.ls.setItem(this.APP_USER, user);
  }

  getPermissions(): Observable<any> {
    return this.http.get(`${environment.apiURL}/partner/show/permission`);
  }

  //[ 'can_read_pms',
  // 'can_change_pms',
  // 'can_read_booking',
  // 'can_read_group_booking_enquires',
  // 'can_read_transfer_bookings',
  // 'can_read_customers',
  // 'can_read_customer_groups',
  // 'can_read_club_pass',
  // 'can_read_coupons',
  // 'can_read_attraction_pass',
  // 'can_change_attraction_pass',
  // 'can_change_booking',
  // 'can_change_club_pass',
  // 'can_change_coupons',
  // 'can_change_customers',
  // 'can_change_group_booking_enquires',
  // 'can_change_transfer_bookings' ]

  checkPermission(permission_type) {
    if (this.permissionData.user_type == 'supplier' && [
      'can_read_pms',
      'can_change_pms',
      'can_read_booking',
      'can_read_voucher_portal'
    ].includes(permission_type)) return true;
    return (this.permissionData.permissions != null && this.permissionData.permissions.length > 0 ? this.permissionData.permissions.some(permission => permission.name === permission_type) : false) || this.permissionData.is_super_admin;
  }

  checkUserType() {
    return this.permissionData?.user_type ?? 'no-user';
  }
}
